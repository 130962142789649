import { request } from '@/lib/service'
import type { responseType } from './type'
export type paramsT = {
  page?: number
  limit?: number
  isRead?: any
  ids?: []
  value?: number
}
// 获取用户信息
export const getMessageApi = (data: paramsT) => {
  return request<responseType>(
    {
      url: `/eesa-industrial-map/client/userMessage/v1.0/findListByProject`,
      method: 'post',
    },
    { data },
  )
}

// 获取未读数
export const getUnreadNum = () => {
  return request<responseType>(
    {
      url: `/api-user/sysUserMessage/findUnreadMessageNum`,
      method: 'post',
    },
    {},
  )
}

// 变更已读状态
export const updateIsRead = (data: paramsT) => {
  return request<responseType>(
    {
      url: `/api-user/sysUserMessage/updateIsRead`,
      method: 'post',
    },
    { data },
  )
}
