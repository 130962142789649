import React from 'react'

import user from './user'
import enterprise from './enterprise'
class RootStore {
  userStore: any
  enterpriseStore: any
  constructor() {
    this.userStore = user
    this.enterpriseStore = enterprise
  }
}

const rootStore = new RootStore()

// context机制的数据查找链  Provider如果找不到 就找createContext方法执行时传入的参数
const context = React.createContext(rootStore)

const useStore = () => React.useContext(context)

export { useStore }
