
/* eslint-disable */
 const aplusQueue =   (w, s, q, i)=> {
  w[q] = w[q] || []
  var f = document.getElementsByTagName(s)[0],
    j = document.createElement(s)
  j.async = true
  j.id = 'beacon-aplus'
  j.src = 'https://d.alicdn.com/alilog/mlog/aplus/' + i + '.js'
  if(process.env.NEXT_PUBLIC_CONFIG !== 'prod')return
  f.parentNode.insertBefore(j, f)
}
  // 点击事件上报
  const sendRecord = (eventCode, eventParams='')=>{
    // 传参不能为空
    if( !eventCode ){
      return console.error('UMClick eventCode is required')
    };
    // 传参必须为String
    if( typeof eventCode !== 'string' ){
      return console.error('UMClick eventCode must be String')
    };
    // 传参必须为String或Number
    if( Object.values(eventParams).some(value => typeof value === 'object' && value !== null) ){
      return console.error('UMClick eventParams value must be String or Number')
    };
    var { aplus_queue } = window;
    try{
        aplus_queue.push({
      action: 'aplus.record',
      arguments: [eventCode, 'CLK', Object.assign({code: eventCode, curtime: new Date}, eventParams)]
    })
    }catch(e){

    }


  };

export  {aplusQueue,sendRecord}
