
export const regIP = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
// 手机号正则
export const regMobile = /^1[23456789][0-9]{9}$/;

// 验证码校验
export const regCode = /^[0-9]{6}$/;

// 验证邮箱
export const regEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

// 验证密码
export const regPassword =
/^(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[!.@#&*_=+-]).{8,16}$/;

export const regHttp = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)/;

/** 登录校验 */
const loginRules ={
  mobile: [
    {
      validator: (value:any, callback:any) => {
        if (value === "") callback(new Error("请输入手机号"));
        else if (!regMobile.test(value))
          callback(new Error("请输入正确的手机号"));
        else callback();
      },
      trigger: "blur"
    }
  ],
  smsCode: [
    {
      validator: ( value:any, callback:any) => {
        if (value === "") callback(new Error("请输入验证码"));
        else callback();
      },
      trigger: "change"
    }
  ]
};

export { loginRules };
