import { makeAutoObservable } from 'mobx'
import { getUserInfo, configListBefore } from '@/api/login'
import { getUnreadNum } from '@/api/message'
class UserStore {
  constructor() {
    makeAutoObservable(this)
  }
  userInfo: any = {}
  imgUrl = ''
  unReadNum = 0 //未读消息数
  setUserInfo = async () => {
    const data = (await getUserInfo()) as any
    if (data.resp_code === 0) {
      this.userInfo = data.datas
    }
  }

  setImgUrl = async () => {
    const value = process.env.NEXT_PUBLIC_CONFIG as any
    const data = (await configListBefore(value)) as any
    if (data.resp_code === 0) {
      this.imgUrl = data.datas[0].url
    }
  }
  setUnRead = async () => {
    const data = await getUnreadNum()
    if (data.resp_code === 0) {
      this.unReadNum = data.datas
    }
  }
}
const userStore = new UserStore()
export default userStore
