import { useEffect, useState } from 'react'
import ModalPage from './index'
function MobileHint() {
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    sessionStorage.removeItem('reloaded')
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      setShowModal(true)
    }
  }, [])
  return (
    <div className="content">
      {showModal ? (
        <ModalPage>
          <p style={{ padding: '50px 20px',fontSize:'14px' }}>暂时不支持手机端，请使用PC电脑端打开链接。</p>
        </ModalPage>
      ) : (
        ''
      )}
    </div>
  )
}

export default MobileHint
